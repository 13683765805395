
import { fetchResponse, GetWithBody } from "./genericFetch";
export interface Segment {
    segmentId: number;
    segmentName: string;
    type: string;
}
export async function getSegmentsByType(type: string): Promise<fetchResponse<Segment[]>> {
    const response = await GetWithBody<Segment[]>("/api/GetSegments?type=" + type);
    return response;
}
