
import { defineComponent } from "vue";
export default defineComponent({
    name: "NavIcons",
    props: ['showNav'],
  
    methods: {
        home: function () {
            this.$router.push("/");
        },
      
    },
    
  
});
