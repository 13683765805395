import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/simple.svg'


const _hoisted_1 = { class: "text-center mobile-img mb-3" }
const _hoisted_2 = { href: "#" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: "",
        width: "300",
        class: "",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.home && _ctx.home(...args)))
      })
    ])
  ]))
}