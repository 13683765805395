import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container-fluid px-0" }
const _hoisted_2 = { id: "main" }
const _hoisted_3 = {
  key: 0,
  class: "custom-margin-logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavIcons = _resolveComponent("NavIcons")!
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_MobileFooter = _resolveComponent("MobileFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mobileView)
      ? (_openBlock(), _createBlock(_component_NavIcons, {
          key: 0,
          onShowNav: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showNav = !_ctx.showNav)),
          showNav: _ctx.showNav
        }, null, 8, ["showNav"]))
      : _createCommentVNode("", true),
    (_ctx.showNav)
      ? (_openBlock(), _createBlock(_component_MobileHeader, {
          key: 1,
          onShowNav: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNav = !_ctx.showNav)),
          class: "custom-margin-header"
        }))
      : _createCommentVNode("", true),
    (!_ctx.mobileView)
      ? (_openBlock(), _createBlock(_component_Header, { key: 2 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.mobileView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component }) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
        ]),
        _: 1
      })
    ]),
    (!_ctx.mobileView)
      ? (_openBlock(), _createBlock(_component_Footer, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.mobileView)
      ? (_openBlock(), _createBlock(_component_MobileFooter, {
          key: 4,
          onSetMargin: _ctx.setMargin
        }, null, 8, ["onSetMargin"]))
      : _createCommentVNode("", true)
  ]))
}