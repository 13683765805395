
import { defineComponent } from "vue";
export default defineComponent({
    name: "Menubar",
    data: function () {
        return {
            show: false as boolean
        };
    },
    methods: {
        home: function () {
            this.$router.push("/");
        },
    },
});
