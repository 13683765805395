
export type fetchResponse<T> = {
    body: T;
    status: number;
}
//used for gets where you receive a response body
export async function GetWithBody<T>(route: string): Promise<fetchResponse<T>> {
    const response = await fetch(route, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json'
        },
    });
    const body = await response.json();
    return { body: body, status: response.status };
}
export async function post<T>(route: string, data: T): Promise<fetchResponse<null>> {
    const response = await fetch(route, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    return { body: null, status: response.status };
}