
import { defineComponent } from "vue";
import * as Seg from "../models/segments";

export default defineComponent({
    name: "Information",
    data: function () {
        return {
            sprint: new Object() as Seg.Segment[],
            // tempo: new Object() as Seg.Segment[],
            hill: new Object() as Seg.Segment[],
            mountain: new Object() as Seg.Segment[]
        };
    },
    methods: {
        getSegments: async function (type: string): Promise<Seg.Segment[]> {
            let response = await Seg.getSegmentsByType(type);
            return response.body;
        },
    },
    created: async function () {
        this.hill = await this.getSegments("Hill");
        // this.tempo = await this.getSegments("Tempo");
        this.mountain = await this.getSegments("Mountain");
        this.sprint = await this.getSegments("Sprint");
    },
});
