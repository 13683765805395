
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import MobileHeader from "./components/MobileHeader.vue";
import NavIcons from "./components/NavIcons.vue";
import MobileImg from "./components/MobileImg.vue";
import MobileFooter from "./components/MobileFooter.vue";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    name: "App",
    components: {
        Header,
        Footer,
        MobileHeader,
        NavIcons,
        MobileImg,
        MobileFooter,
    },
    data: function () {
        return {
            mobileView: false,
            showNav: false,
            margin: 0,
        };
    },
    methods: {
        handleView() {
            this.mobileView = window.innerWidth <= 900;
        },
        setMargin(value: string) {
            let div = document.getElementById("main");
            if (div === null) {
                return;
            }
            if (window.innerWidth > 800) {
                div.style.marginBottom = "150px";
            } else {
                div.style.marginBottom = value;
            }
        },
    },
    created() {
        this.handleView();
        window.addEventListener("resize", this.handleView);
    },
    onBeforeUnmount() {
        window.removeEventListener("resize", this.handleView);
    },
});
