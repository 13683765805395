
import { defineComponent } from "vue";
export default defineComponent({
    name: "MobileImg",

    methods: {
        home: function () {
            this.$router.push("/");
        },
    },
});
