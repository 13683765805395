
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Results from "../components/Results.vue";
import Information from "../components/Information.vue";
import Registration from "../components/Registration.vue";
import Strava from "../components/Strava.vue";
import Personvernerklæring from "../components/Personvernerklæring.vue";
import NotFound from "../components/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Results",
        component: Results,
    },
    {
        path: "/info",
        name: "Information",
        component: Information,
    },
    {
        path: "/registration",
        name: "Registration",
        component: Registration,
    },
    {
        path: "/exchange_token",
        name: "Strava",
        component: Strava,
    },
    {
        path: "/privacy",
        name: "Personvernerklæring",
        component: Personvernerklæring,
    },
    {
        path: '/:pathMatch(.*)*',
        name: "NotFound",
        component: NotFound,
    }


];

const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
