
import { defineComponent } from "vue";
import * as Res from "../models/results";
import * as Seg from "../models/segments";
import Swal from "sweetalert2";

export default defineComponent({
    name: "Results",
    data: function () {
        return {
            test: Array(20).fill(0),
            results: new Object() as Res.Result[],
            segments: new Object() as Seg.Segment[],
            options: {
                type: "Sprint" as string,
                // sex: "M" as string,
            },
            active: {
                Sprint: true,
                // Tempo: false,
                Hill: false,
                Mountain: false //,
                // Male: true,
                // Female: false,
            },
            // activeSex: {},
            timer: 0,
            windowSize: 0 as number,
            counter: 0 as number
        };
    },

    methods: {
        getResults: async function (): Promise<void> {
            let response = await Res.getResults(
                this.options.type
                // ,this.options.sex
            );
            this.clearTimer(this.timer); // prevents from calling api twice in a row

            if (response.status === 200) {
                this.results = response.body;
            }
        },
        getSegments: async function (): Promise<void> {
            let response = await Seg.getSegmentsByType(this.options.type);
            if (response.status === 200) {
                this.segments = response.body;
            }
        },
        changeType: function (type: string) {
            this.options.type = type;
        },
        // changeSex: function (sex: string) {
        //     this.options.sex = sex;
        // },
        clearTimer: function (timer: number) {
            clearTimeout(timer);
        },
        resize: function () {
            this.windowSize = window.innerWidth;
        },
        alert: function () {
            if (window.innerWidth < 700) {
                Swal.fire({
                    text: "Anbefaler å vende mobilen",
                    confirmButtonColor: "#002F46",
                    icon: "warning",
                });
            }
            window.removeEventListener("resize", this.alert);
        },
    },
    onBeforeUnmount() {
        window.removeEventListener("resize", this.resize);
    },

    watch: {
        options: {
            handler() {
                this.getSegments();
                this.getResults();
            },
            deep: true,
        },
    },
    created: async function () {
        await this.getSegments();
        await this.getResults();
    },
    mounted: async function () {
        this.alert();
        this.resize();
        window.addEventListener("resize", this.alert);
        window.addEventListener("resize", this.resize);
    },
});
