
import { defineComponent } from "vue";
import { auth, createAthlete } from "../models/createAthlete";
export default defineComponent({
    name: "Strava",
    data: function () {
        return {
            code: false,
            scope: false,
            error: false,
            systemfail: false,
            loading: true,
            userExist: false,
        };
    },
    mounted: async function () {
        // grab params from url
        // check if error/cancelled -->then give notice to user that he did not complet
        // check if scope is ok --> if not, give notice
        // all good --> send to backend

        let params = new URLSearchParams(location.search);
        let code = params.get("code");
        let scope = params.get("scope")?.search("activity:read");
        let error = params.get("error");

        if (error !== null) {
            this.loading = false;
            this.error = true;
        } else if (scope === -1) {
            this.loading = false;
            this.scope = true;
        } else if (code !== null) {
            let auth = { code: code } as auth;
            let response = await createAthlete(auth);
            if (response.status === 200) {
                this.loading = false;
                this.code = true;
            } else if (response.status === 401 || response.status === 500) {
                this.loading = false;
                this.systemfail = true;
            } else {
                this.loading = false;
                this.userExist = true;
            }
        }
        history.pushState({}, "", `${this.$route.path}`);
    },
});
