
import { fetchResponse, GetWithBody } from "./genericFetch"
export interface Result {
    athleteName: string;
    athleteId: number;
    type: string;
    rank: number
    totalScore: number;
    segmentResults: SegmentResult[];
}
export interface SegmentResult {
    segmentId: number;
    segmentName: string;
    time: number;
    activityId: number;
    score: number;
}
// export async function getResults(type: string, sex: string): Promise<fetchResponse<Result[]>> {
//     const response = await GetWithBody<Result[]>("/api/GetResults?type=" + type + "&sex=" + sex)
//     return response;
export async function getResults(type: string): Promise<fetchResponse<Result[]>> {
    const response = await GetWithBody<Result[]>("/api/GetResults?type=" + type)
    return response;
}

