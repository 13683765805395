
<template>
    <footer class="container-fluid w-100 mobile-footer" ref="mobileFooter">
        <div class="row pb-2 pt-3">
            <div class="col-md align-self-center">
                <div class="logo-footer mx-auto">
                    <router-link to="/"
                        ><img class="py-2" src="../assets/banner_thick.svg"
                    /></router-link>
                    <a href="https://strava.com/" target="_blank"
                        ><img
                            class="pt-2"
                            src="../assets/powered-by-strava.svg"
                    /></a>
                </div>
            </div>
            <div class="col-md text-center mt-2 align-self-center">
                <div class="row">
                    <div class="col"></div>
                    <a class="footer-mail" href="mailto:mail@klatrekongen.no"
                        >mail@klatrekongen.no</a
                    >
                </div>
                <div class="row mt-3">
                    <div class="col">
                        <router-link to="/privacy"
                            >Personvernerklæring</router-link
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        Developed by
                        <a href="https://www.linkedin.com/in/livselleunderhaug/" target="_blank"
                            >Liv Selle Underhaug</a
                        >
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        &copy; 2022
                        <a
                            href="https://www.linkedin.com/company/switchbacknorway" target="_blank"
                            >Switchback Norway AS</a
                        >
                    </div>
                </div>
            </div>

           
        </div>
    </footer>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
    name: "MobileFooter",
    methods: {
        handleView() {
            if (this.$refs.mobileFooter === null) {
                return;
            }
            let margin = this.$refs.mobileFooter.clientHeight + "px";
            this.$emit("setMargin", margin);
        },
    },
    mounted() {
        this.handleView();
        window.addEventListener("resize", this.handleView);
    },
    OnBeforeUnmount() {
        window.removeEventListener("resize", this.handleView);
    },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
