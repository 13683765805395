
import { defineComponent } from "vue";
export default defineComponent({
    name: "Registration",
    data: function () {
        return {
            stravaId: process.env.VUE_APP_STRAVA_ID,
            redirect: process.env.VUE_APP_REDIRECT,
            scope: process.env.VUE_APP_SCOPE
        };
    },
    methods: {
        connectWithStrava: function () {
            window.open(
                `http://www.strava.com/oauth/authorize?client_id=${this.stravaId}&response_type=code&redirect_uri=${this.redirect}/exchange_token&approval_prompt=force&scope=${this.scope}`
            );
        },
    },
});
